import Header from '../../Components/Fixed/Header';
import Footer from '../../Components/Fixed/Footer';

import { useState, useEffect } from "react";
import { customAxios } from '../../Components/Common/customAxios';
import { useNavigate } from 'react-router-dom';

import CircularProgress from '@mui/material/CircularProgress';
import { pageCollection } from '../../Components/Page/PageCollection';

//IMG
import Logo from '../../Images/Layout/logo.png';

function Join() {

    const navigate = useNavigate()

    const noticeGreen = "text-green-500  text-right mt-2 whitespace-pre-line";
    const noticeRed = "text-Cgcolor  text-right mt-2 whitespace-pre-line";

    const buttonDisable = "group relative justify-center rounded-md border border-gray-500 bg-gray-600 py-2 px-4  font-medium text-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
    const buttonAble = "group relative justify-center rounded-md border border-Cgcolor bg-Black py-2 px-4  font-medium text-white hover:bg-Cgcolor focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"

    const [start, setStart] = useState(false)
    const [open, setOpen] = useState(false)


    const [email, setEmail] = useState("");
    const [password1, setPassword1] = useState("");
    const [password2, setPassword2] = useState("");
    const [nickname, setNickname] = useState("");
    const [checkedList, setCheckedList] = useState([]);

    const [emailStr, setEmailStr] = useState("");
    const [passwordStr, setPasswordStr] = useState("");
    const [nicknameStr, setNicknameStr] = useState("");
    const [registrationStr, setRegistrationStr] = useState("");

    const [emailValidated, setEmailValidated] = useState();
    const [passwordValidated, setPasswordValidated] = useState();
    const [nicknameValidated, setNicknameValidated] = useState();
    const [nicknameChecking, setNicknameChecking] = useState(false)

    const [seconds, setSeconds] = useState(parseInt(0));

    useEffect(() => {

        setStart(true)

    }, [])

    useEffect(() => {

        if (start) {
            setOpen(true)
        }
    }, [start])

    const onInputFormDialogYes = (param) => {
        if (param.Code === "chang-ggo-beta0306") {
            setOpen(false)
        }
        else {
            alert("베타 테스트 코드가 일치하지 않습니다.")
        }
    }
    const onInputFormDialogNo = () => {
    }

    const handleEmail = (e) => { setEmail(e.target.value); }
    const handlePassword1 = (e) => { setPassword1(e.target.value); }
    const handlePassword2 = (e) => { setPassword2(e.target.value); }
    const handleNickname = (e) => { setNickname(e.target.value); }
    const handleCheckAll = (e) => {
        (e.target.checked)
            ? setCheckedList(["join_age", "join_service", "join_privacy"])
            : setCheckedList([]);
    }
    const handleCheck = (e) => {
        e.target.checked
            ? setCheckedList([...checkedList, e.target.name])
            : setCheckedList(checkedList.filter((choice) => choice !== e.target.name));

        // console.log(e.target.name);

    }

    const [submitValidated, setSubmitValidated] = useState(false);

    const handleSubmit = async (event) => {
        event.preventDefault();
        event.stopPropagation();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            console.log("not Validate")
        }
        else {

            if (nicknameValidated === true && passwordValidated === true && checkedList.length === 3) {

                //이메일 인증 확인 코드 추가

                //회원가입 진행
                setRegistrationStr("")

                const res = await customAxios.post('/api/insertMember', {
                    email: email,
                    password: password1,
                    nickname: nickname,
                })

                if (res.data.state === 100) {
                    alert("회원가입이 완료되었습니다. \n 로그인페이지에서 로그인을 진행해주세요")
                    navigate("/Login", { replace: true })

                }
                else {
                    if (res.data.message === "Not Certification") {
                        setRegistrationStr("이메일 인증이 완료되지 않았습니다.")
                    }
                    else {
                        setRegistrationStr("회원가입 등록에 실패하였습니다. \n 새로고침 후 다시 시도해주세요.")
                    }
                }
            }

        }
        setSubmitValidated(true);

    };

    const clickEmailAuthButton = (e) => //이메일 인증 버튼 클릭
    {
        //이메일 유효성 검사
        let regExp = /^[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/i
        let valiDated = regExp.test(email);

        if (valiDated) {
            customAxios.post('/api/checkEmail',
                {
                    email: email,
                })
                .then((res) => {
                    // console.log(res.data);
                    if (res.data.state === 100) {
                        //이메일 중복 없음
                        setEmailStr("인증메일이 발송되었습니다. \n 메일의 링크를 클릭 해주세요");
                        setEmailValidated(true);

                        //이메일 발송
                    }
                    else {

                        //삭제된 이메일
                        if(res.data.isDelete === true){
                            setEmailStr("탈퇴한 회원입니다. 복구하려면 로그인 하세요.");
                        }
                        else{
                            //이메일 중복
                            setEmailStr("가입되어 있는 이메일입니다. 확인 바랍니다.");
                        }
                        
                        setEmailValidated(false);
                    }
                })
        }
        else {
            //이메일 형식이 맞지 않습니다.
            setEmailStr("이메일 형식이 맞지 않습니다.");
            setEmailValidated(false);
        }
    }

    useEffect(() => {
        let reg = /[\{\}\[\]\/?.,;:|\)*~`!^\-_+<>@\#$%&\\\=\(\'\"]/gi
        if (reg.test(nickname)) {

            setSeconds(-1);
            setNicknameStr("닉네임에 특수문자를 사용할 수 없습니다.");
            setNicknameValidated(false);
            setNicknameChecking(false)

        }
        else {

            if (nickname.length >= 3) {
                setSeconds(3);
                setNicknameStr("닉네임 중복 확인 중 입니다.");
                setNicknameValidated(false);
                setNicknameChecking(true)

            }
            else {
                setSeconds(-1);
                setNicknameStr("닉네임의 길이가 3자리 이상이여야 합니다.")
                setNicknameValidated(false);
                setNicknameChecking(false)
            }

        }

    }, [nickname])


    useEffect(() => {
        const countdown = setInterval(() => {
            if (parseInt(seconds) > 0) {
                setSeconds(parseInt(seconds) - 1);
            }
            if (parseInt(seconds) === 0) {
                customAxios.post('/api/checkNickname',
                    {
                        nickname: nickname,
                    })
                    .then((res) => {
                        //   console.log(res.data);
                        if (res.data.state === 100) {
                            //닉네임 중복 없음
                            setNicknameStr("사용 가능한 닉네임입니다.");
                            setNicknameValidated(true);
                            setNicknameChecking(false);

                        }
                        else {
                            //닉네임 중복
                            setNicknameStr("이미 사용 중인 닉네임입니다.");
                            setNicknameValidated(false);
                            setNicknameChecking(false);
                        }
                    })
                clearInterval(countdown);
            }
        }, 1000);

        return () => clearInterval(countdown);

    }, [seconds]);


    useEffect(() => {
        if (password1.length < 8 || password2.length < 8) {
            setPasswordStr("비밀번호의 길이가 8자리 이상이여야 합니다.");
            setPasswordValidated(false);
        }
        else {

            if (password1 === password2) {
                setPasswordStr("두 비밀번호가 일치합니다.");
                setPasswordValidated(true);
            }
            else {
                setPasswordStr("두 비밀번호가 일치하지 않습니다.");
                setPasswordValidated(false);
            }
        }

    }, [password1, password2])


    return (
        <div>
            <Header></Header>
            <section>
                <article className='bg-Maincolor1 text-white break-keep py-20 pt-32 '>
                    <div className='mx-auto min-h-screen max-w-5xl px-5 flex justify-center flex-col'>
                        <div className="flex flex-shrink-0 items-center justify-center mb-5">
                            <img className='Cglogo' src={Logo} alt='logo' />
                        </div>
                        <div className='text-center'>
                            <h1 className='font-bold text-xl mb-3'>회원가입</h1>
                        </div>
                        {/* form  start*/}
                        {/* 이메일 찾기 기능에 휴대폰 번호 필요하지 않나유? */}
                        <form className='mt-10' noValidate validated={submitValidated ? "true" : "false"} onSubmit={handleSubmit}>
                            <p className='text-right mb-3 '><span className='text-Cgcolor'>*</span> 필수 입력 정보입니다.</p>
                            <div>
                                <h2 className='mb-2'><span className='text-Cgcolor'>*</span> 이메일</h2>
                                <div className='flex'>
                                    <input
                                        required
                                        type="email"
                                        name="formEamil"
                                        id="formEamil"
                                        className="text-black block w-full flex-1 rounded border-gray-300 focus:border-Cgcolor focus:ring-Cgcolor focus:ring-Cgcolor  mr-2"
                                        placeholder="이메일을 입력해 주세요."
                                        disabled={emailValidated}
                                        onChange={handleEmail}
                                    />
                                    <button
                                        type="button"
                                        className={emailValidated ? buttonDisable : buttonAble}

                                        disabled={emailValidated}
                                        onClick={clickEmailAuthButton}
                                    >
                                        이메일 인증
                                    </button>
                                </div>
                                <p
                                    className={emailValidated ? noticeGreen : noticeRed}>
                                    {emailStr}
                                </p>
                            </div>
                            <div className='mt-5'>
                                <h2 className='mb-2'><span className='text-Cgcolor'>*</span> 비밀번호</h2>
                                <div>
                                    <input
                                        required
                                        maxLength="64"
                                        type="password"
                                        name="formPassword1"
                                        id="formPassword1"
                                        className="text-black block w-full rounded border-gray-300 focus:border-Cgcolor focus:ring-Cgcolor focus:ring-Cgcolor sm:"
                                        onChange={handlePassword1}
                                    />
                                </div>
                            </div>
                            <div className='mt-5'>
                                <h2 className='mb-2'> <span className='text-Cgcolor'>*</span>비밀번호 확인</h2>
                                <div>
                                    <input
                                        required
                                        maxLength="64"
                                        type="password"
                                        name="formPassword2"
                                        id="formPassword2"
                                        className="text-black block w-full rounded border-gray-300 focus:border-Cgcolor focus:ring-Cgcolor focus:ring-Cgcolor sm:"
                                        onChange={handlePassword2}
                                    />
                                </div>
                                <p className={passwordValidated ? noticeGreen : noticeRed}>
                                    {passwordStr}
                                </p>
                            </div>
                            <div className='mt-5'>
                                <h2 className='mb-2'> <span className='text-Cgcolor'>*</span> 닉네임</h2>
                                <div className='flex'>
                                    <input
                                        required
                                        maxLength="60"
                                        type="text"
                                        name="formNickname"
                                        id="formNickname"
                                        className="text-black block w-full flex-1 rounded border-gray-300 focus:border-Cgcolor focus:ring-Cgcolor focus:ring-Cgcolor  mr-2"
                                        onChange={handleNickname}
                                    />

                                </div>
                                <div className='mt-3 flex justify-end'>
                                    <div className={nicknameValidated ? noticeGreen : noticeRed}>
                                        {nicknameStr}
                                    </div>
                                    <div>
                                        {nicknameChecking ? <CircularProgress className='ml-3' /> : null}
                                    </div>

                                </div>


                            </div>
                            {/* agree start*/}
                            <div className='bg-white rounded-lg py-5 px-5 mt-10'>
                                <div className='space-y-5'>
                                    <div className="flex items-start pb-5 mb-5 border-b">
                                        <div className="flex h-5 items-center">
                                            <input id="join_all" name="comments" required type="checkbox" className="h-4 w-4 rounded border-gray-300 text-Cgcolor focus:ring-Cgcolor"
                                                onChange={handleCheckAll}
                                                checked={checkedList.length === 3 ? true : false}
                                            />
                                        </div>
                                        <div className="ml-3 ">
                                            <label htmlFor="join_all" className="font-medium text-gray-700">모두 동의합니다.</label>
                                        </div>
                                    </div>
                                    <div className="flex items-start">
                                        <div className="flex h-5 items-center">
                                            <input id="join_age" name="join_age" required type="checkbox" className="h-4 w-4 rounded border-gray-300 text-Cgcolor focus:ring-Cgcolor"
                                                onChange={handleCheck}
                                                checked={checkedList.includes("join_age") ? true : false}
                                            />
                                        </div>
                                        <div className="ml-3 ">
                                            <label htmlFor="join_age" className="font-medium text-gray-700">만 14세 이상입니다. <span className='text-Cgcolor'>(필수)</span></label>
                                        </div>
                                    </div>
                                    <div className="flex items-start">
                                        <div className="flex h-5 items-center">
                                            <input id="join_service" name="join_service" required type="checkbox" className="h-4 w-4 rounded border-gray-300 text-Cgcolor focus:ring-Cgcolor"
                                                onChange={handleCheck}
                                                checked={checkedList.includes("join_service") ? true : false}

                                            />
                                        </div>
                                        <div className="ml-3 ">
                                            <label htmlFor="join_service" className="font-medium text-gray-700 block">개인정보 수집/이용에 동의합니다. <span className='text-Cgcolor'>(필수)</span></label>
                                            <a href={pageCollection.etcPages[0].url} target="_blank" className='text-gray-400 block mt-3 hover:text-black'>[{pageCollection.etcPages[0].name}]</a>
                                        </div>
                                    </div>
                                    <div className="flex items-start">
                                        <div className="flex h-5 items-center">
                                            <input id="join_privacy" name="join_privacy" required type="checkbox" className="h-4 w-4 rounded border-gray-300 text-Cgcolor focus:ring-Cgcolor"
                                                onChange={handleCheck}
                                                checked={checkedList.includes("join_privacy") ? true : false}
                                            />
                                        </div>
                                        <div className="ml-3 ">
                                            <label htmlFor="join_privacy" className="font-medium text-gray-700 block">서비스 이용약관에 동의합니다. <span className='text-Cgcolor'>(필수)</span></label>
                                            <a href={pageCollection.etcPages[1].url} target="_blank" className='text-gray-400 block mt-3 hover:text-black'>[{pageCollection.etcPages[1].name}]</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <p className={noticeRed}>
                                {registrationStr}
                            </p>
                            {/* agree end*/}

                            <button
                                type="submit"
                                className="w-2/5 mx-auto mt-10 block rounded-lg  bg-Cgcolor py-3 px-3  font-bold text-white"
                            >
                                회원가입
                            </button>
                        </form>


                        {/* form  end*/}
                    </div>
                </article>

            </section>
            <Footer></Footer>


        </div>


    );
}

export default Join;


