

import Header from '../../Components/Fixed/Header';
import Footer from '../../Components/Fixed/Footer';

import {  useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

// LOGIN IMG
import Logo from '../../Images/Layout/changggo_white.png';
import NAVER_LOGIN from '../../Images/Login/btnG_icon_square.png';
import KAKAO_LOGIN from '../../Images/Login/kakao.png';
import GOOGLE_LOGIN from '../../Images/Login/google.png';

import { v4 } from 'uuid';
import { useDispatch, useSelector } from 'react-redux';
import { setLogin } from '../../Store/loginSlice';
import { LoginEmail, customAxios } from '../../Components/Common/customAxios';

function Login() {

    const [email,setEmail] = useState("");
    const [password,setPassword] = useState("");
    const [notice, setNotice] = useState("");
    const [showModal, setShowModal] = useState(false);
    const [state, setState] = useState(null)
    const [searchParams, setSearchParams] = useSearchParams()

    const navigate = useNavigate()
    const location = useLocation()
    const dispatch = useDispatch()

    const [start, setStart] = useState()
    const loginState = useSelector((state) => state.login)
    
    const emailRef = useRef()
    const passwordRef = useRef()


    const onClickKakao = () =>{

        const KAKAO_AUTH_URL = "https://kauth.kakao.com/oauth/authorize?response_type=code&"
        +"client_id="+process.env.REACT_APP_REST_API_KAKAO+"&"
        // +"redirect_uri="+process.env.REACT_APP_REDIRECT_URI_KAKAO+"&prompt=login"
        +"redirect_uri="+process.env.REACT_APP_REDIRECT_URI_KAKAO

        window.location.href=KAKAO_AUTH_URL

    }
    const onClickNaver = () =>{
        
        const NAVER_AUTH_URL = "https://nid.naver.com/oauth2.0/authorize?response_type=code&"
        +"client_id="+process.env.REACT_APP_REST_API_NAVER+"&"
        +"state="+v4()+"&"
        +"redirect_uri="+process.env.REACT_APP_REDIRECT_URL_NAVER

        window.location.href=NAVER_AUTH_URL
    }

    const onClickGoogle = () =>{

        const GOOGLE_AUTH_URL = 
        "https://accounts.google.com/o/oauth2/v2/auth?"
        // +"scope=https%3A//www.googleapis.com/auth/drive.metadata.readonly&"
        +"scope=https://www.googleapis.com/auth/userinfo.email&"
        +"access_type=offline&"
        +"include_granted_scopes=true&"
        +"response_type=code&"
        +"state="+v4()+"&"
        +"redirect_uri="+process.env.REACT_APP_REDIRECT_URL_GOOGLE+"&"
        +"client_id="+process.env.REACT_APP_GOOGLE_ID+"&"
        +"prompt=consent"

        window.location.href=GOOGLE_AUTH_URL
    }

    const onClickChangGgo = ()=>{
        setShowModal(true)
    }
    useEffect(()=>{
        setStart(true)
    },[])

    useEffect(() => {

        if(start){
            if (loginState.isLogin === true) {
                const nextPage = localStorage.getItem("nextPage")
                console.log(nextPage)

                localStorage.removeItem("nextPage")

                if(nextPage){
                    navigate(nextPage, { replace: true })
                }
                else{
                    navigate("/Home", { replace: true })
                }
            }
        }
    }, [start, loginState.isLogin])

    useEffect(()=>{

        if(state === null){
            if(location.state){
                setState(location.state)
            }

        }
    }, [state,location.state])

  
    
    useEffect(()=>{
        if(showModal===true){
            emailRef.current.focus()
        }
    },[showModal])

    
    const onClickLogin = async (e) =>{
        e.preventDefault();

        setNotice("")

        const result = await customAxios.post("/api/LoginDelete",{email,password})
        // console.log(result.data)

        if(result.data.state===101){
            
            //탈퇴한 회원 확인            
            if(result.data.isExist === true){
                if(result.data.confirm === true){
                    //탈퇴한 회원입니다. 복구하시겠습니까?

                    if(window.confirm("탈퇴한 회원입니다.\n복구하시겠습니까?")){
                        const restorationMember = await customAxios.post("/api/restorationMember",{email,password})
                        
                        if(restorationMember.data.state ===100){

                            alert("복구가 완료되었습니다. 다시 로그인 해주세요")
                        }
                    }
                }
                else{
                    setNotice("아이디 혹은 비밀번호가 일치하지 않습니다.")
                    passwordRef.current.focus()
                }
            }
            else if(result.data.isExist === false){
                const res = await LoginEmail(email, password)

                if (res.state === 101) {
                    setShowModal(false)
                    const getMember = await customAxios.get('/api/getMember')
                    const state = {}
                    if (getMember.data.state === 101) {
                        state.isLogin = true
                        state.Member_UID = getMember.data.Member.Member_UID
                        state.Member_Email = getMember.data.Member.Member_Email
                        state.Member_Nickname = getMember.data.Member.Member_Nickname
                        state.Member_Auth = getMember.data.Member.Member_Auth
                        state.Member_TokenType = getMember.data.Member.Member_TokenType
                        dispatch(setLogin(state))
                    }
                    else {
                        console.log("getMember 실패")
                    }

                    // const nextPage = localStorage.getItem("nextPage")
                    // localStorage.removeItem("nextPage")

                    // if(nextPage){
                    //     navigate(nextPage, { replace: true })
                    // }
                    // else{
                    //     navigate("/Home", { replace: true })
                    // }
                }
                else {
                    setNotice("아이디 혹은 비밀번호가 일치하지 않습니다.")
                    passwordRef.current.focus()
                }
            }
        }
    }

    return (
        <div>
            <Header/>
            <section>
                <article className='bg-black text-white break-keep py-24'>
                    <div className='mx-auto max-w-7xl pt-10 px-5 flex justify-center flex-col'>
                        <div className="flex flex-shrink-0 items-center justify-center mb-5">
                            <img className='Cglogo2' src={Logo} alt='logo' />
                        </div>
                        <div className='text-center'>
                            <h1 className='font-bold mb-3'>창꼬와 함께하세요.</h1>
                            {/* <h2 className='opacity-80 mb-3 '>최고의 전문가들이 끝까지 함께합니다.</h2> */}
                            <div className='max-w-xs mt-10 mx-auto font-bold space-y-5'>
                                <button
                                    onClick={onClickNaver}
                                    className='w-full block box-border rounded-xl bg-Navercolor text-white flex justify-center items-center h-12'>
                                        <div><img className="w-9 h-9" src={NAVER_LOGIN} alt='NAVER'/></div>
                                        <span>네이버 로그인</span>
                                </button>
                                
                                <button
                                    onClick={onClickKakao}
                                    className='w-full  box-border py-5 px-5 rounded-xl bg-Kakaocolor flex justify-center items-center h-12'>
                                        <div className='w-9 h-9 flex justify-center items-center'><img className="w-4 h-4" src={KAKAO_LOGIN} alt='KAKAO'/></div>
                                        <span className='text-Kakaocolor-text'>카카오 로그인</span>
                                </button>

                                <button
                                    onClick={onClickGoogle}
                                    className='w-full  box-border py-5 px-5 rounded-xl bg-white flex justify-center items-center h-12'>
                                        <div className='w-9 h-9 flex justify-center items-center mr-1.5'><img className="w-4 h-4" src={GOOGLE_LOGIN} alt='GOOGLE'/></div>
                                        <span className='text-black'>구글 로그인</span>
                                </button>

                                <button
                                    onClick={onClickChangGgo}
                                    className='w-full  box-border py-5 px-5 rounded-xl bg-Cgcolor cursor-pointer flex justify-center items-center h-12'>
                                    창꼬 로그인
                                </button>

                            </div>
                            <div className='max-w-xs mt-5 mx-auto font-bold border-t pt-5 border-gray-700'>
                                <a href="/Join" className=' box-border rounded-xl bg-white text-black flex justify-center items-center h-12'>회원가입</a>
                            </div>
                        </div>   
                    </div>
                </article>
            </section>
            <Footer></Footer>  

        <> 
        {showModal ? (
        <>
        <form onSubmit={onClickLogin}>
            <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none ">
                <div className="relative w-full my-6 mx-auto max-w-3xl box-border px-5">
                {/*content*/}
                <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white focus:outline-none text-black">
                    {/*header*/}
                    <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t ">
                        <h3 className="text-sm font-bold">
                            창꼬 로그인
                        </h3>
                    </div>
                    {/*body*/}
                    <div className="relative p-6 flex-auto text-left ">
                        <div className="mb-6">
                            <label id="name" className="block mb-2  font-medium">이메일</label>
                            <input type="text" id="Email" 
                            className="bg-gray-50 border border-gray-300 text-gray-900  rounded-lg focus:ring-Cgcolor focus:border-Cgcolor block w-full p-2.5  " 
                            placeholder="" required onChange={(e)=>{setEmail(e.target.value)}}
                            ref = {emailRef} 
                            // onKeyDown = {OnKeyPress}
                            />
                        </div>
                        <div className="mb-6">
                            <label id="name" className="block mb-2  font-medium">비밀번호</label>
                            <input type="password" id="Password" 
                            className="bg-gray-50 border border-gray-300 text-gray-900  rounded-lg focus:ring-Cgcolor focus:border-Cgcolor block w-full p-2.5  " 
                            placeholder="" required onChange={(e)=>{setPassword(e.target.value)}}
                            ref = {passwordRef} 
                            // onKeyDown = {OnKeyPress}
                            />
                        </div>
                        <div className="mb-1 text-red-700">
                        {notice}
                        </div>

                    </div>
                    {/*footer*/}
                    <div className="p-6 border-t border-solid border-slate-200 rounded-b">
                        <button
                        className="bg-Cgcolor rounded-lg text-white font-bold uppercase px-5 py-3  outline-none focus:outline-none mr-1"
                        type="submit"  
                        >
                        로그인
                        </button>
                        <button
                        className="rounded-lg border font-bold  px-5 py-3 outline-none focus:outline-none"
                        type="button"
                        onClick={() => {setShowModal(false)}}
                        >
                            취소
                        </button>
                        <a href="/Lost" className='block text-right mt-5'>비밀번호 찾기</a>
                    </div>
                    
                </div>
                </div>
            </div>
            </form>
            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
        ) : null}
        </>

        </div>
        
              
    );
}
  
  export default Login;


