

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import Lee from '../Images/Main/main05.png';

import { TypeAnimation } from 'react-type-animation';
import { Link } from 'react-router-dom';
import React, { Component } from "react";
import Slider from "react-slick";

import Header from '../Components/Fixed/Header';
import Footer from '../Components/Fixed/Footer';
//SLICK
function SampleNextArrow(props) {
    const { className, style, onClick } = props;

    return (
        <div
            className={className}
            style={{ ...style, right: -20, }}
            onClick={onClick}
        />
    );
}

function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style, left: -20, zIndex: 1, }}
            onClick={onClick}
        />
    );
}
//SLICK END

function Home() {

    const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 4,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        responsive: [
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                infinite: false,
                dots: false
              }
            },
            {
              breakpoint: 600,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                initialSlide: 2
              }
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1
              }
            }
          ]
    };

    return (
        <div>
            <Header />
            <section>
                <article
                    //className='bg-[url("./Images/bg.jpg")]'
                    className='main-visual_wrap bg-black'
                >
                    <div className='pt-24 mx-auto max-w-7xl px-5'>
                        <div className='md:flex py-10'>
                            <div className='md:flex-1'>
                                <div className='text-white '>
                                    <h1>
                                        창꼬는 "창고"를 발음대로 쓴 말로, 물건이나 자재를
                                    </h1>
                                    <h2>
                                        저장해두는 창고처럼 모든 문화적,
                                    </h2>
                                    <h3>
                                        예술적 요소들을 담아두고 있다는 뜻입니다
                                    </h3>

                                    <div className='md:text-2xl text-xl font-bold mt-3 mb-5'>
                                        <TypeAnimation
                                            sequence={[
                                                '창꼬의 전문가들과 함께, 실현 가능한 목표로 만드세요.', // Types 'One'
                                                1000, // Waits 1s
                                                ' 당신의 목표를 이루기 위해 창꼬가 함께합니다.', // Deletes 'One' and types 'Two'
                                                2000, // Waits 2s

                                                () => {
                                                    // console.log('Done typing!'); // Place optional callbacks anywhere in the array
                                                }
                                            ]}
                                            wrapper="div"
                                            cursor={true}
                                            repeat={Infinity}
                                            style={{ fontSize: '1em' }}
                                        />
                                    </div>
                                    <ul className='flex space-x-3 text-black'>
                                        <li className='rounded-xl bg-white opacity-75 box-border py-2 px-2'>#보컬</li>
                                        <li className='rounded-xl bg-white opacity-75 box-border py-2 px-2'>#댄스</li>
                                        <li className='rounded-xl bg-white opacity-75 box-border py-2 px-2'>#뮤지컬</li>
                                        <li className='rounded-xl bg-white opacity-75 box-border py-2 px-2'>#오디션</li>
                                        <li className='rounded-xl bg-white opacity-75 box-border py-2 px-2'>#취미</li>
                                    </ul>
                                </div>
                            </div>
                            <div className='md:flex-1 md:w-1/2 md:mt-0 mt-5'>
                                <div>
                                    <img src={Lee} className=' w-full' alt='메인 이미지' />
                                </div>
                            </div>
                        </div>
                    </div>

                </article>
                {/* <article className='bg-Maincolor1 text-white'>
                    <div className='mx-auto max-w-7xl py-10 px-5 youtube'>
                        <div className='flex justify-between '>
                            <h1 className='text-sm font-bold mb-5'>창꼬실용음악학원</h1>
                            <h2 className='text-xs  font-bold mb-10'><Link to="/Class">더 보기</Link> </h2>                               
                        </div>                                   
                        <div className='Main_slider'>
                            <Slider {...settings}>
                                <div>
                                    <div className='mx-5'><img src={Trainer01} className='w-full rounded' alt='트레이너1'/></div>
                                </div>
                                <div>
                                    <div className='mx-5'><img src={Trainer02} className='w-full rounded' alt='트레이너2'/></div>
                                </div>
                                <div>
                                    <div className='mx-5'><img src={Trainer03} className='w-full rounded' alt='트레이너3'/></div>
                                </div>
                                <div>
                                    <div className='mx-5'><img src={Trainer04} className='w-full rounded' alt='트레이너4'/></div>
                                </div>
                                <div>
                                    <div className='mx-5'><img src={Trainer05} className='w-full rounded' alt='트레이너5'/></div>
                                </div>
                                <div>
                                    <div className='mx-5'><img src={Trainer06} className='w-full rounded' alt='트레이너6'/></div>
                                </div>
                                <div>
                                    <div className='mx-5'><img src={Trainer07} className='w-full rounded' alt='트레이너7'/></div>
                                </div>
                                <div>
                                    <div className='mx-5'><img src={Trainer08} className='w-full rounded' alt='트레이너8'/></div>
                                </div>
                                <div>
                                    <div className='mx-5'><img src={Trainer09} className='w-full rounded' alt='트레이너9'/></div>
                                </div>
                                <div>
                                    <div className='mx-5'><img src={Trainer10} className='w-full rounded' alt='트레이너10'/></div>
                                </div>
                                <div>
                                    <div className='mx-5'><img src={Trainer11} className='w-full rounded' alt='트레이너11'/></div>
                                </div>
                                <div>
                                    <div className='mx-5'><img src={Trainer12} className='w-full rounded' alt='트레이너12'/></div>
                                </div>
                                <div>
                                    <div className='mx-5'><img src={Trainer13} className='w-full rounded' alt='트레이너13'/></div>
                                </div>
                            </Slider>
                        </div>
                    </div>
                </article>
                <article className='bg-Maincolor1 text-white'>
                    <div className='mx-auto max-w-7xl py-10 px-5 pb-20 youtube'>
                        <div className='flex justify-between '>
                            <h1 className='text-sm font-bold mb-5'>창꼬 스토어</h1>
                            <h2 className='text-xs  font-bold mb-10'><Link to="/Store?storeCategoryID=01">더 보기</Link> </h2>                               
                        </div>                                   
                        <div className='Main_slider'>
                            <Slider {...settings}>
                                <div>
                                    <div className='mx-5'><img src={Store2} className='w-full rounded' alt='상품2'/></div>
                                </div>
                                <div>
                                    <div className='mx-5'><img src={Store3} className='w-full rounded' alt='상품3'/></div>
                                </div>
                                <div>
                                    <div className='mx-5'><img src={Store4} className='w-full rounded' alt='상품4'/></div>
                                </div>
                                <div>
                                    <div className='mx-5'><img src={Store5} className='w-full rounded' alt='상품5'/></div>
                                </div>
                            </Slider>
                        </div>
                    </div>
                </article> */}
            </section>
            <Footer></Footer>
        </div>

    );
}

export default Home;


