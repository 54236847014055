import Header from '../../Components/Fixed/Header';
import Footer from '../../Components/Fixed/Footer';

import MyMenu from '../../Components/MyChangGgo/MyMenu';
import MyPasswordConfirm from '../../Components/MyChangGgo/MyPasswordConfirm';

import MyPrivacyButtons from '../../Components/MyChangGgo/MyPrivacyButtons';
import MyEmail from '../../Components/MyChangGgo/MyEmail';
import MyNickname from '../../Components/MyChangGgo/MyNickname';
import MyPassword from '../../Components/MyChangGgo/MyPassword';
import MyLeave from '../../Components/MyChangGgo/MyLeave';

import { useState, useEffect } from "react";

import Logo from '../../Images/Layout/logo_new.png';
import NAVER_LOGIN from '../../Images/Login/btnG_icon_square.png';
import KAKAO_LOGIN from '../../Images/Login/kakao.png';
import GOOGLE_LOGIN from '../../Images/Login/google.png';
import { useSelector } from 'react-redux';
import MyCertification from '../../Components/MyChangGgo/MyCertification';
import { useNavigate } from 'react-router-dom';

function MyPrivacy() {
    const loginState = useSelector((state) => state.login)

    const [privacyModeState, setPrivacyModeState] = useState(0);
    const [passwordState, setPasswordState] = useState(false);
    const [originalPassword, setOriginalPassword] = useState("");
    const navigate = useNavigate()

    useEffect(() => {
        if (loginState.isLogin === true) {
            setPrivacyModeState(5)

            // if (loginState.Member_TokenType !== "C")
            //     setPrivacyModeState(5)
        }
        else if (loginState.isLogin === false) {
            alert("로그인이 필요합니다.")
            navigate("/")
        }

    }, [loginState.isLogin, loginState.Member_TokenType])

    useEffect(() => {
        if (passwordState === true) {
            setPrivacyModeState(5);
        }
    }, [passwordState])

    const MyPrivacyTokenType = () => {
        let result = []
        let result2 = []

       
        if (loginState.Member_TokenType === 'K') {
            result2.push(
                <div key={"tokenType"} className='flex items-center'>
                    <div className='bg-Kakaocolor rounded-lg w-9 h-9 flex justify-center items-center mr-2'>
                        <img className="w-4 h-4" src={KAKAO_LOGIN} alt='KAKAO' />
                    </div>
                    <span className='text-black'>카카오 계정입니다.</span>
                </div>
            )
        }
        else if (loginState.Member_TokenType === 'N') {
            result2.push(
                <div key={"tokenType"} className='flex items-center'>
                    <div className='bg-Navercolor rounded-lg w-9 h-9 flex justify-center items-center mr-2'>
                        <img className="w-9 h-9" src={NAVER_LOGIN} alt='NAVER' />
                    </div>
                    <span className='text-black'>네이버 계정입니다.</span>
                </div>
            )
        }
        else if (loginState.Member_TokenType === 'G') {
            result2.push(
                <div key={"tokenType"} className='flex items-center'>
                    <div className='bg-white rounded-lg w-9 h-9 flex justify-center items-center mr-2 border'>
                        <img className="w-4 h-4" src={GOOGLE_LOGIN} alt='GOOGLE' />
                    </div>
                    <span className='text-black'>구글 계정입니다.</span>
                </div>
            )
        }
        else {
            result2.push(
                <div key={"tokenType"} className='flex items-center'>
                    <div className='bg-white rounded-lg w-20 flex justify-center items-center mr-2 borde'>
                        <img className="w-full" src={Logo} alt='CHANG-GGO' />
                    </div>
                    <span className='text-black'>창꼬 계정입니다.</span>
                </div>
            )
        }


        result.push(
            <div key={"MyPrivacyTokenType"} className='basic text-black box-border py-5 px-5'>
                <div className='border-gray-500'>
                    <div className='font-bold'>
                        {result2}
                    </div>
                </div>
            </div>
        )

        if(loginState.isLogin){
            return result
        }

    }

    const MyPrivacyBody = () => {
        if (privacyModeState === 0) {
            return (
                <MyPasswordConfirm
                    passwordState={passwordState}
                    setPasswordState={setPasswordState} />
                /*비밀번호 확인*/
            );
        }
        else {

            let result = []
            result.push(
                <MyPrivacyButtons /*개인정보 수정 메뉴*/
                    key="MyPrivacyButtons"
                    setPrivacyModeState={setPrivacyModeState}
                    privacyModeState={privacyModeState} />
                    )

            if (privacyModeState === 1) {
                result.push (<MyEmail key="MyEmail"/> );
            }
            else if (privacyModeState === 2) {
                result.push (<MyNickname key="MyNickname"/>  );
            }
            else if (privacyModeState === 3) {
                result.push (
                        <MyPassword key="MyPassword"
                            setOriginalPassword={setOriginalPassword}
                            originalPassword={originalPassword} />  
                );
            }
            else if (privacyModeState === 4) {
                result.push (<MyLeave key="MyLeave"/>  );
            }
            else if (privacyModeState === 5) {
                result.push (<MyCertification key="MyCertification"/>  );
            }

            return (
                <div key="MyPrivacyBody">
                    {result}
                </div>
            )

        }


    }

    return (
        <div>
            <Header />
            <section>
                <article className='bg-Maincolor1 text-white break-keep py-20 pt-32'>
                    <div className='mx-auto min-h-screen max-w-7xl px-5 md:flex md:justify-between'>
                        <div className='box-border flex flex-wrap flex-col items-center mb-10 md:w-1/4 md:mb-0'>
                            <MyMenu />
                        </div>

                        <div className='box-border bg-white rounded-lg md:w-width_70'>
                            {MyPrivacyTokenType()}
                            {MyPrivacyBody()}
                        </div>
                    </div>
                </article>

            </section>
            <Footer></Footer>
        </div>


    );
}

export default MyPrivacy;


