import Header from '../../Components/Fixed/Header';
import Footer from '../../Components/Fixed/Footer';

import MyMenu from '../../Components/MyChangGgo/MyMenu';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';

function MyPage() {

    const loginState = useSelector((state) => state.login)
    const navigate = useNavigate()

    useEffect(() => {
        if (loginState.isLogin === true) {
        }
        else if (loginState.isLogin === false) {
            alert("로그인이 필요합니다.")
            navigate("/")
        }

    }, [loginState.isLogin])

    return (
        <div>
            <Header/>
            <section>
                <article className='bg-Maincolor1 text-white break-keep py-20 pt-32'>
                    <div className='mx-auto min-h-screen max-w-76xl px-5 md:flex md:justify-between'>
                        <div className='box-border flex flex-wrap flex-col items-center mb-10 md:w-1/4 md:mb-0'>
                            <MyMenu/>
                        </div>
                        <div className='box-border bg-white rounded-lg md:w-width_70'>
                            {/* <My_Alarm/>    */}
                            <div className='p-5 text-black'>
                                마이페이지 기본
                            </div>
                           
                        </div>
                    </div>   
                </article>
                
            </section>
            <Footer/>
        </div>
        
              
    );
}
  
  export default MyPage;


