import Header from "../../Components/Fixed/Header";
import Footer from "../../Components/Fixed/Footer";

import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { EventList } from "../../Components/Event/EventList";


function EventBoard() {

    const [start, setStart] = useState(false)
    const loginState = useSelector((state) => state.login)

    return (
        <div className='break-keep'>
            <Header />
            <div className='flex flex-col px-2 pt-24 items-center'>
                {/* <Typography variant="h5" color='blue-gray' className="p-2 self-center font-bold font-sans">창꼬 소식</Typography> */}
                <EventList/>
            </div>
            <Footer />
        </div>
    )
}

export default EventBoard;
