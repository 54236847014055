import Header from '../../Components/Fixed/Header';
import Footer from '../../Components/Fixed/Footer';

import MyMenu from '../../Components/MyChangGgo/MyMenu';
import { customAxios } from '../../Components/Common/customAxios';
import { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';


import PaymentDisplay from '../../Components/Payment/PaymentDisplay';
import { pageCollection } from '../../Components/Page/PageCollection';


export default function MyPayment() {

    const [start, setStart] = useState(false)
    const [myPayment, setMyPayment] = useState(null)

    const loginState = useSelector((state) => state.login)
    const navigate = useNavigate()

    const GetMyPayment = async () => {
        const result = await customAxios.get("/api/getMyPayment")
        setMyPayment(result.data.response)
    }

    useEffect(() => {
        setStart(true)
    }, [])

    useEffect(() => {
        const asyncGetMyPayment = async () => {
            const result = await customAxios.get("/api/getMyPayment")

            setMyPayment(result.data.response)
        }

        if (loginState.isLogin === false) {
            alert("로그인이 필요합니다.")
            navigate("/")
        }
        else if (loginState.isLogin === true) {
            if (start) {
                asyncGetMyPayment()
            }
        }
    }, [start, loginState.isLogin])


    return (
        <div>
            <Header />
            <section>
                <article className='bg-Maincolor1 text-white break-keep py-20 pt-32'>
                    <div className='mx-auto min-h-screen max-w-7xl px-5 md:flex md:justify-between'>
                        <div className='box-border flex flex-wrap flex-col items-center mb-10 md:w-1/4 md:mb-0'>
                            <MyMenu />
                        </div>
                        <div className='box-border bg-white rounded-lg md:w-width_70 p-5'>
                            <div className='text-black'>
                                {pageCollection.ManagementMemberPages[11].name}
                            </div>
                            <PaymentDisplay
                                myPayment={myPayment}
                                GetMyPayment={GetMyPayment}
                            />
                        </div>
                    </div>
                </article>

            </section>
            <Footer />
        </div>
    );
}


