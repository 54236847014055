
import Header from '../../Components/Fixed/Header';
import Footer from '../../Components/Fixed/Footer';

import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { customAxios } from '../../Components/Common/customAxios';


function ChangeEmail() {

    const noticeGreen = "text-green-500  text-right mt-2 whitespace-pre-line";
    const noticeRed = "text-Cgcolor  text-right mt-2 whitespace-pre-line";

    const location = useLocation();
    const navigate = useNavigate();
    const [token, setToken] = useState(null)
    const [tokenCheck, setTokenCheck] = useState(null)


    useEffect(() => {

        let param = new URLSearchParams(location.search)

        if (param.get('Token') === null) {
            setTokenCheck(false)
        }
        else {
            setToken(param.get('Token'))
        }

    }, [])

    useEffect(() => {

        if (token !== null) {
            customAxios.get("/api/checkChangeEmail?Token=" + token)
                .then((res) => {
                    if (res.data.state === 100) {
                        setTokenCheck(true)
                    }
                    else {
                        setTokenCheck(false)
                    }

                })
        }

    }, [token])

    const getSection = () => {

        if (tokenCheck === true) {
            return (
                <section>
                    <article className='bg-Maincolor1 text-white break-keep py-24'>
                        <div className='mx-auto max-w-5xl px-5 flex justify-center flex-col'>
                            <form className='mt-10'>
                                <h2 className='mb-2 whitespace-pre-line'>
                                    {"이메일 변경이 완료되었습니다."}
                                </h2>
                            </form>
                        </div>
                    </article>
                </section>
            )
        }
        else if (tokenCheck === false) {
            return (
                <section>
                    <article className='bg-Maincolor1 text-white break-keep py-24'>
                        <div className='mx-auto max-w-5xl px-5 flex justify-center flex-col'>
                            <form className='mt-10'>
                                <h2 className='mb-2 whitespace-pre-line'>
                                    {"올바르지 않은 경로로 접근하였거나 인증 메일이 만료되었습니다. \n 비밀번호 찾기를 다시 시도해주세요."}
                                </h2>
                            </form>
                        </div>
                    </article>
                </section>
            )
        }
        else if (tokenCheck === null) {
            return (
                <section>
                    <article className='bg-Maincolor1 text-white break-keep py-24'>
                        <div className='mx-auto max-w-5xl px-5 flex justify-center flex-col'>
                            <form className='mt-10'>
                                <h2 className='mb-2'>
                                    인증 진행 중 입니다.
                                </h2>
                            </form>
                        </div>
                    </article>
                </section>
            )
        }

    }



    return (
        <div>
            <Header></Header>
            {getSection()}
            <Footer></Footer>
        </div>


    );
}

export default ChangeEmail;


